@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
#header {
  --bs-navbar-padding-y: 0.75rem;
  background: linear-gradient(180deg, rgb(var(--bs-primary-rgb)) 0%, rgba(var(--bs-primary-rgb), 0.95) 100%);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15), 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
  .logo {
    max-height: 1.75rem;
  }

  .nav-link {
    &.active {
      font-weight: 700;
    }
    &.icon-link {
      width: 36px;
      height: 36px;
    }
  }

  .answer-navBar {
    font-size: 1rem;
    padding: 0.25rem 0.5rem;
    border: none;
  }
  .answer-navBar:focus {
    box-shadow: none;
  }

  .lg-none {
    display: none!important;
  }

  .hr {
    color: var(--bs-navbar-color);
  }

  // style for colored navbar
  &.theme-colored {
    .placeholder-search {
      box-shadow: none;
      color: #fff;
      background-color: rgba(255, 255, 255, .2);
      border: $border-width $border-style rgba(255, 255, 255, .2);
      &:focus {
        border: $border-width $border-style $border-color;
      }
      &::placeholder {
        color: rgba(255, 255, 255, 0.75);
      }
    }
  }

  // style for colored navbar
  &.theme-light {
    background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.95) 100%);
  }
}


@media (max-width: 992.9px) {
  #header {
    .logo {
      max-width: 93px;
    }
    .nav-grow {
      flex-grow: 1!important;
    }

    .lg-none {
      display: flex!important;
    }

    .w-75 {
      width: 100% !important;
    }
  }

}

@media (max-width: 576px) {
  #header {
    .logo {
      max-width: 93px;
    }
  }
}


